@media only screen and (max-width: 1290px) and (min-width: 600px) {
    .main_heading{
        font-size: 18px !important;
    }

    .main_heading_state{
        font-size: 11px !important;
        padding-top: 8px !important;
    }
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
    .side_container_wrapper{
        display: flex !important;
        min-width: 60px !important;
     } 
     .menu_label{
         display: none;
     } 
     .product_details_img{
         height: 150px !important;
     }

     .main_container{
         margin-top: 9rem !important;
     }

     .auth_img_res_wrapper{
         height: 200px !important;
     }

     .auth_res_img{
         height: 128px !important;
     }

     .reg_form_img_wrapper{
         height: auto !important;
     }
     .reg_form_img{
         height: 40vh !important;
     }

     .address_button{
         display: flex;
         flex-direction: column;
     }

     .sub_heading{
        flex-direction: column !important;
        height: 125px !important;
        top: 105px !important;
        justify-content: center !important;
        align-items: inherit !important;
     }

     .sub_heading_title{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 0px;
     }

     .specfication_block_wrapper ul{
         display: flex;
         border-right: none !important;
     }
     .product_spec_left{
         width: 66vw !important;
     }
     .order_sub_heading{
        justify-content: flex-start !important;
        flex-direction: unset !important;
        align-items: center !important
     }

     .logo{
         height: 50px !important;
     }

     .emc_logo{
         height: 50px !important;
     }
     .my_ev_logo{
        height: 66px !important;
     }

 

     .main_logo{
         margin-right: 0px !important;
         height: 66px !important;
     }
     .catalogue_header_wrapper{
         padding: 0 !important;
     }

     .catalogue_top_wrapper {
         top :75px !important;
         padding: 18px 0px !important;
     }

     .catalogue_footer_wrapper{
         padding: 2px !important;
     }

     .not_found_img{
         height: 168px !important;
     }

     .price_block{
         width: 100% !important;
     }

     .footerImage{
        width: 105px !important;
        height: 40px !important;
     }

     .xs_heading{
         font-size: xx-small;
     }

     .x_heading{
         font-size: small;
     }

     .auth_img_wrapper{
         height: auto !important;
     }

     .auth_img{
         height: 40vh !important;
     } 

     .remaining_btn{
         display: inherit !important;
     }

     .order_paper{
         width: auto !important;
     }

     .product_filter_block{
         width: 85% !important;
     }

     .contact_us_img{
         height: 150px;
     }


     .contact_us_wrapper{
         display: flex;
         flex-direction: column-reverse;

     }

     .product_menu_active{
    
        font-size: 11px !important;
 
      }

      .product_menu{
          font-size: 11px !important;
          cursor: pointer;
      }
    .main_heading{
        font-size: 11px !important;
    }

    .main_heading_state{
        font-size: 11px !important;
        padding-top: 8px !important;
    }

}

.avatar{
    color: white;
    background-color: rgb(255, 145, 77);
    height: 30px !important;
    width: 30px !important;
    font-size: small !important;
 }

.order_sub_heading{
top:145px;
}


.product_spec_left{
    overflow: auto;
}

.product_details_img{
    height: 330px;
}

.auth_img_res_wrapper{
    height: 400px;
}

.auth_res_img{
    height: 280px;
}

.auth_img_wrapper{
    height: 100vh;
}
.auth_img{
    height: 70vh;
}

.reg_form_img_wrapper{
    height: 100vh;
}
.reg_form_img{
    height: 91.5vh;
}

.catalogue_header_wrapper{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    /* align-items: center; */
    background-color:#f4f9f9;
}
.catalogue_footer_wrapper{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
}

.title{
    font-size: 12px;
}
.sub_title{
    font-size: 8px;
    color: rgb(207, 207, 207);
}
.sub_title_dark{
    font-size: 8px;
    color: rgb(119, 119, 119);
}
.catalogue_top_wrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 94px;
}

h1,h2,h3,h4,h5,h6{
    color: #064A76;
}

li{
    list-style: none;
}

/* margins */
.m-5{
    margin: 5px !important;
}
.m-10{
    margin: 10px !important;
}

.m-b-10{
    margin-bottom: 10px !important;
}
.m-b-20{
    margin-bottom: 20px !important;
}


/* padding */

.p-5{
    padding: 5px !important;
}
.p-10{
    padding: 10px !important;
}
.p-12{
    padding: 12px !important;
}
.p-20{
    padding: 20px !important;
}
.p-30{
    padding: 30px !important;
}

.p-b-5{
    padding-bottom: 5px !important;
}
.p-b-10{
    padding-bottom: 10px !important;
}
.p-b-12{
    padding-bottom: 12px !important;
}
.p-b-15{
    padding-bottom: 15px !important;
}
.p-b-20{
    padding-bottom: 20px !important;
}
.p-b-30{
    padding-bottom: 30px !important;
}

.p-t-5{
    padding-top: 5px !important;
}
.p-t-10{
    padding-top: 10px !important;
}
.p-t-12{
    padding-top: 12px !important;
}
.p-t-15{
    padding-top: 15px !important;
}
.p-t-20{
    padding-top: 20px !important;
}
.p-t-30{
    padding-top: 30px !important;
}

.p-r-5{
    padding-right: 5px;
}



.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: 4%; */
    background-color: #F4F9F9;
    color: white;
    text-align: center;
    z-index: 6;
 }

 .center{
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .flex_end{
     display: flex;
     justify-content: flex-end;
     width: 100%;
 }

 .flex_even{
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     width: 100%;
 }

 .flex_around{
     display: flex;
     justify-content: space-around;
     align-items: center;
     width: 100%;
 }
 .flex_between{
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
 }

 .modal_button_wrapper{
     display: flex;
     justify-content: space-between;
     flex-direction: row;
 }

 .container_main_wrapper{
 padding: 23px;
 flex-grow: 1;
 }

 .view_wrapper{
     display: flex;
     flex: 1 1;
     top:12%;
     bottom: 5%;
     position: absolute;
     width: 100%;
 }

 .side_container_wrapper{
    display: flex;
    min-width: 130px;
 }
 .side_sub_wrapper{
    display: flex;
    position: fixed;
    height: -webkit-fill-available;
    align-items: center;
 }

 .side_sub_wrapper ul{
    /* padding: 15px 0px; */
    background-color: #064A76 ;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
 }
 .side_sub_wrapper li{
    padding: 15px;
    margin: 5px 0px;
    cursor: pointer;
 }
 /* .side_sub_wrapper li:hover{
    background-color:#064A76;
 } */

 .sub_heading{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: fixed;
    z-index: 6;
    top: 153px;
    align-items: center;
    width: 100%;
    height: 13vh;
 }

 .product_filter_block{
    display: flex;
    position: fixed;
    top : 166px;
    flex-direction: column;
    width: 90%;
 }

 .product_filter_block_first{
    display: flex;
    justify-content: space-between;
    
 }

 .product_filter_block_second{
    display: flex;
    /* justify-content: flex-end; */
   
    padding: 10px 0px;
 }
 .status_heading {
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    color: dark blue;
    font-weight: 600;
}

.status_heading:hover {
    font-weight: 700 !important;
}

 .dark_heading{
     color: #000 !important;
     font-weight: 600;
 }
 .red_dark_heading{
     color: red !important;
     font-weight: 600;
 }

 .imgFit{
    object-fit: contain;
 }

 .pointer{
     cursor:pointer;
 }
 .main_container{
     margin-top: 10rem;
     padding-bottom: 4rem;
 }
 
 .product_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

 }

 .product_color_round_wrapper{
     display: flex;
     flex-wrap: wrap;
 }

 .product_color_round{
    border: 1px solid #203594 ;
    padding: 2px;
    width: 10px;
    margin: 5px;
    height: 10px;
    border-radius: 10px;
 }
 .product_color_round__selected{
    border: 2px solid #203594 ;
    padding: 2px;
    width: 10px;
    margin: 5px;
    height: 10px;
    border-radius: 10px;
 }
 .product_image_round{
    border: 1px solid #203594;
    width: 50px;
    margin: 5px;
    height: 50px;
    border-radius: 10px;
    cursor:pointer;
 }
 .product_image__selected{
    border: 2px solid #203594;
 }


 .specfication_block_wrapper{
    text-align: left;
 }

 .specfication_block_wrapper ul{
    border-right: 1px solid rgb(182, 182, 182);  
 }

 .specfication_block_wrapper li{
    padding: 15px;
    margin: 5px 0px;
 }
 .specfication_block_wrapper li:hover{
   background-color: #00BBE7;
 }
 .specfication_block_wrapper__Selected{
    background-color: #00BBE7;
  }

 .details li{
    padding: 15px;
    margin: 5px 0px;
 }

 .dealer_block{
     display: flex;
     padding: 10px;
     align-items: center;
     margin-bottom: 15px;
     font-size: 14px;
     color: #fff;
     background-color: #9CCB3B;
 }
 .dealer_block_data{
     display: flex;
     padding: 10px;
     align-items: center;
     margin-bottom: 5px;
     color: #fff;
     background-color:#d3d4da;
     color:#000
 }
 .inv_selected{
    background-color:#203594;
    color: #fff;
 }

 .filter_icon_wrapper{
    height: 44x;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price_block{
    width: 70%;
}


.filter_icon {
    height:36px;
    width:36px;
    transition:all 0.2s ease ;

}
.filter_icon:hover {
    height:44px;
    width:44px;
}

.spec_table_wrapper{
    height: 245px !important;
    overflow: auto;
}

.table_wrapper{
    width: 100%;
}

.table_wrapper td{
    padding: 1rem;
}
.table_wrapper tr{
   font-weight: 500;
}

.not_found{
    flex-direction: column;
    padding: 10px;
}

.not_found_img{
    height: 400px;
}

.test_vehicle{
    padding: 10px 28px;
    background-color: #9aca3e;
    position: absolute;
    color: #000;
    font-weight: 600;
    /* width: 27%; */
    -webkit-transform: translate(-39px,1px) rotate(-40deg);
    transform: translate(-39px,1px) rotate(-40deg);
    
}

.dealership_block{
    height: 216px;
    width: 100%;
    overflow-x: auto;
}

.active_order{
    color: #00BBE7;
    text-decoration: underline;
    font-size: large;
    font-weight: 600;
}
.red_heading{
    color : #AD272A;
}

.footer_right_block{
display: flex;
color: #000;
font-weight: 600;
}

.footer_right_block li{
    margin-right: 8px;
    cursor: pointer;
}

.contact_us_info{
    display: flex;
    align-items: center;
}

.footer_icon{
    font-size: 20px;
}


.about_us_data{
    padding-bottom: 15px;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: larger;
    text-align: justify;
}

.font_color_black{
    color : #000
}

.contact_us_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  .product_menu_active{
    padding-right: 10px;
    text-decoration: underline;
    font-size: 21px;
    color:#FF914D;
  }

  .product_menu{
    padding-right: 10px;
    font-size: 21px;
    cursor: pointer;
  }

  .price_block_alt{
        height: 36vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px !important;
  }

  .faq_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 54vh;
  }

  .faq_ques_wrapper{
    display: flex;
    align-items: center;
    width: 82vw;
    justify-content: space-between;
    padding: 6px 0px;
  }
  .faq_container_wrapper{
      height: 54vh;
      overflow: auto;
  }
  .main_heading{
      font-weight: 400;
      font-size: 25px;
      color: #000;
  }

  .main_heading_state{
      font-size: 15px;
      padding-top: 20px;
  }

  .input_margin_bottom{
      margin-bottom: 8px;
  }

  .input_upload_button{
      margin-top: 13px;
  }

    .instruction_list {
        list-style: upper-roman;
        margin-top: 10px;
    }

    .loan_button {
        float: right;
        width: 140;
        height: 50;
        margin-right: 6%;
        background: #1E4473;
        border-radius: 10;
        color: #FFFFFF
    }
    .declaration{
        color: #FF914D;
    }
