@media only screen and (max-width: 600px) and (min-width: 300px) {
    .search__view{
        min-width: 0px !important;
    } 
    .search__view>input {
        width: 90px !important;
    } 
}
.search__view {
    display: flex;
    min-width: 250px;
    height: 35px;
    background: rgba(32, 53, 148, 0.05);
    border: 1px solid #D7DBEA;
    box-sizing: border-box;
    border-radius: 10px;
    outline: 0;
    align-items: center;
    margin-right: 10px
}

.search__view>input {
    outline: 0;
    background-color: transparent;
    border: none;
    display: flex;
    flex: 1;
    padding: 5px;
    height: 25px;
    color: #203594;
}

.search__view>input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #203594;
    opacity: 0.5;
    /* Firefox */
}

.search_icon {
    height: 20px;
    margin-right: 10px;
    color: rgba(32, 53, 148, 0.5);
}
