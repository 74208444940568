:root {
    --primary: #203594;
    --light: #20359440;
    --error: #FF0000;
    --bg: rgba(196, 196, 196, 0.1);
    --border: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
   .select{
       width : 90px !important;
        margin-right: 30px !important;
   } 

   .select__options{
       width: 100px !important;
   }

   .modal_list{
       display :flow-root !important;
   }
}
.select {
    display: flex;
    padding: 5px;
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: 5px;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    width: 200px;
}

.select-clicked{
    border: 1px solid #203594;
}
.select__options {
    position: absolute;
    background: #ffffff;
    display: flex;
    flex: 1;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    width: 100%;
    overflow: hidden;
    z-index: 200;
}


.select__options>ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.select__options>ul>li, .select>span {
    font-size: 0.75rem;
    color: var(--primary);
}

.select__options li {
    padding: 5px 5px;
    cursor: pointer;
}

.select__options li:hover {
    background-color: #203594;
    color: #ffffff;
}
.state_list{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    filter: grayscale(1);
}

.state_selected {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    filter: initial;
}

.modal_list{
    display: flex;
   
}

.state_list:hover{
    filter: initial;
}

.state_list_cont li:hover{
    color: #000;
    font-weight: bold;
}