:root {
    --primary: #203594;
    --light: #20359440;
    --error: #FF9494;
    --bg: rgba(196, 196, 196, 0.1);
    --border: rgba(0, 0, 0, 0.1);
}

.input {
    display: flex;
    padding: 10px;
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: 5px;
    outline: none;
    color: var(--primary);
    font-size: 0.75rem;
    flex: 1;
    font-family: Montserrat, sans-serif;
    margin: 5px 0;
    min-width: 200px;
}

.input:focus {
    border: 1px solid var(--primary);
}

.input::placeholder {
    color: var(--light);
}

.input-error {
    border: 1px solid var(--error);
}

.input__errormessage {
    color: var(--error);
}
.viewV{
    display: flex;
    flex-direction: column;
}