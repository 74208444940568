@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
}
/* @media only screen and (max-width: 600px) and (min-width: 300px) {
  body{
    font-size: 9px !important;
  }
  
} */

body{
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

input, div, a, p, html {
  font-family: Montserrat, sans-serif;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}


/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px dimgray;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: gray;
}