
@media only screen and (max-width: 600px) and (min-width: 300px) {
   .map_card{
       width: 66vw !important;
   } 

   .vehicle_img{
       height: 50px;
   }
   .google_img{
       height: 25px;
   }
}

.map_card{
    position: absolute;
    top: 60vh;
    margin: 10px;
    /* padding: 6px; */
    /* left: 106px; */
    background-color: #fff;
    padding: 10px;
    width: 20vw;
    /* height: 200px; */
   
}

