@import url(../catalogue/Catalogue.css);
@media only screen and (max-width: 600px) and (min-width: 300px) {
    .address_main_wrapper{
        width: 100% !important;
    }

    .address_wrapper{
        width: 100% !important;
    }

    .top_section{
        width: 70vw !important;
    }
    .bottom_section{
        width: 70vw !important;
    }

    .img_height{
        height: 150px !important;
    }
    .summery_img{
        height: 110px !important;
    }
    
    
}

.divider{
    border-top: 2px dashed #000;
    margin: 20px 0px;
}

.confirm_sec_3{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.address_wrapper{
    overflow: auto;
    width: 82%;
}

.address_paper{
    display :flex;
    flex-direction: column;
    height: 64vh;

}



