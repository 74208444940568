@import url(../catalogue/Catalogue.css);

@media only screen and (max-width : 600px) and (min-width : 300px) {
    
.sign_form_wrapper{
    
    justify-content: inherit !important ;
    height: auto !important;
  
}
    
}

.sign_form_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 90vh;
        padding: 30px;
        align-self: center;
        padding-bottom: 5px;
}

.input_button_verify{
    margin-top: 11px !important;
    margin-left: 10px !important;
    margin-right: 30px !important;
}

.input_button_send{
    margin-top: 11px !important;
}

.form_view{
    justify-content:center;
    align-items:center;
    width:100%;
    display:flex;
    background:#e9edff
}

.top_img_wrapper{
    display: flex;
    flex-direction: column;
}

.top_heading_wrapper{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot_form_wrapper{
    margin: 20px;
    border-radius: 40px !important;
}

.m_t_40{
    margin-top: 40px;
}
.m_b_40{
    margin-bottom: 40px;
}
.m_t_20{
    margin-top: 20px;
}
.m_t_10{
    margin-top: 10px;
}

.p_50{
    padding: 50px;
}