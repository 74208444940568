@media only screen and (max-width: 600px) and (min-width: 300px) {
    .stepper-line{
        margin-left: 5.5rem !important;
    }
  
}

.progress-wrapper{
    display: flex;
    justify-content: space-evenly;
}

.progress-wrapper li{
position: relative;
text-align: center;
}

.progress-wrapper li:first-child::after{
    content: none;
}


.progress-wrapper li:before{
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    border: 1px solid;
    border-radius: 50%;
    margin-left: 50%;
    z-index: 10;
   
}

.progress-wrapper li:after{
    content: '';
    position: absolute;
    background-color: #ddd;
    width: 124px;
    height: 1px;
    left: -60%;
    top: 3px;
   z-index: 1;
}.progress-wrapper li:last-child::after{
    width: 65px;
}
.stepper-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepper-outter-wrapper{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.stepper-label{
    padding: 0.5rem;
    font-weight: 500;
}

.stepper-circle{
    border-radius: 80%;
    height: 10px;
    width: 10px;
    border: 1px solid;
    background-color: #bdbdbd;
    z-index: 1;
}

/* .stepper-line{
    height: 1px;
    background-color:#bdbdbd;
    width: 90%;
    top: 11%;
    position: absolute;
    left: 4%;
} */

.stepper-line{
    height: 2px;
    background-color: #bdbdbd;
    width: 100%;
    margin-left: 6.5rem;
    margin-top: -2.4rem;
}

.stepper-line-active{
    height: 1px;
    background-color:#51A653;
    width: 90%;
    top: 11%;
    position: absolute;
    left: 4%;
}